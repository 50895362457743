var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"ma-4",attrs:{"flat":""}},[_c('v-card-title',{staticClass:"ma-2"},[(_vm.getAdminViewUserId)?_c('span',[_vm._v(" Buttons saved by "+_vm._s(_vm.getUserById(_vm.getAdminViewUserId).email)+" ")]):_vm._e(),(_vm.adminView && !_vm.getAdminViewUserId)?_c('span',[_vm._v(" Select a user from the User List ")]):_vm._e(),(!_vm.adminView)?_c('span',[_vm._v(" Your Saved Buttons ")]):_vm._e()]),_c('v-card-text',{staticClass:"d-flex align-content-start flex-wrap"},[(_vm.getButtons.length && !(_vm.adminView && !_vm.getAdminViewUserId))?_vm._l((_vm.getButtons),function(buttonData){return _c('v-card',{key:buttonData.id,staticClass:"pa-2 ma-2",attrs:{"outlined":""}},[_c('v-card-title',[_vm._v(" Button for "+_vm._s(buttonData.button.sourceCode)+" ")]),_c('v-card-subtitle',[_vm._v(" Generated: "+_vm._s(buttonData.timestamp.toDate().toLocaleString())),_c('br')]),_c('v-card-text',[_c('div',{staticClass:"mt-2 overline text-center"},[_vm._v("Preview")]),_c('div',{style:('display: flex;flex-wrap: wrap;' +
              (buttonData.button.center
                ? 'align-items: center;justify-content: center;'
                : '') +
              'max-width: ' +
              _vm.containerWidth +
              'px;margin: 0 auto;')},[(
                buttonData.button.forActBlue &&
                buttonData.button.actBlue.expressLane
              )?_c('span',{style:(buttonData.button.actBlue.expressLaneDisclaimerStyle)},[_vm._v(" "+_vm._s(buttonData.button.actBlue.expressLaneDisclaimer)+" ")]):_vm._e(),_vm._l((buttonData.button.buttonStack),function(buttonBit,index){return _c('div',{key:index,style:('margin: ' + buttonData.button.spacing + 'px;')},[_c('a',{style:(_vm.buttonStyle(buttonData.button)),attrs:{"href":buttonBit.url}},[_vm._v(" "+_vm._s(buttonBit.text)+" ")])])})],2)]),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"success"},on:{"click":function($event){return _vm.loadButton(buttonData.button)}}},[_vm._v("Load as new button")]),_c('v-btn',{attrs:{"color":"error"},on:{"click":function($event){return _vm.removeButton(buttonData)}}},[_vm._v("Delete Button")])],1)],1)}):[(_vm.adminView && _vm.getAdminViewUserId)?_c('span',[_vm._v(" User has not saved any buttons ")]):_vm._e(),(_vm.adminView && !_vm.getAdminViewUserId)?_c('span',[_vm._v(" Click the \"View Content\" button for a user ")]):_vm._e(),(!_vm.adminView)?_c('v-card',[_c('v-card-title',[_vm._v("No saved buttons")]),_c('v-card-text',[_vm._v("Looks like you haven't saved any buttons yet.")]),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"success","to":"/"}},[_vm._v("Create a button")])],1)],1):_vm._e()]],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }