

































































































import Vue from 'vue'
import { mapGetters, mapActions } from 'vuex'
import { ButtonData, ButtonObject } from '@/models/interfaces'

export default Vue.extend({
  name: 'ButtonList',
  data() {
    return {}
  },
  props: {
    adminView: Boolean,
  },
  computed: {
    ...mapGetters(['getButtons', 'getUserById', 'getAdminViewUserId']),
  },
  watch: {
    getAdminViewUserId(userId) {
      this.bindButtonsToDb(userId)
    },
  },
  methods: {
    ...mapActions(['bindButtonsToDb', 'updateWorkingButton', 'deleteButton']),
    loadButton(buttonConfig: ButtonObject) {
      this.updateWorkingButton(buttonConfig)
      this.$router.push('/')
    },
    removeButton(buttonData: ButtonData) {
      const confirmText =
        'Are you sure you want to delete this button "' +
        buttonData.button.sourceCode +
        '"?'
      const confirmed = confirm(confirmText)
      if (confirmed) {
        this.deleteButton(buttonData.id)
      }
    },
    buttonStyle(buttonConfig: ButtonObject) {
      return (
        'display: inline-block;' +
        'color: #ffffff;' +
        'text-align: center;' +
        'text-decoration: none;' +
        'font-weight: bold;' +
        'line-height: ' +
        buttonConfig.height +
        'px;' +
        'font-family: ' +
        buttonConfig.fontFamily +
        ';' +
        'width: ' +
        buttonConfig.width +
        'px;' +
        'background-color: ' +
        buttonConfig.color +
        ';' +
        'border-radius: ' +
        buttonConfig.radius +
        'px;' +
        'border-color: ' +
        buttonConfig.borderColor +
        ';' +
        'border-width: ' +
        buttonConfig.borderWidth +
        'px;' +
        'border-style: ' +
        buttonConfig.borderStyle +
        ';' +
        'font-size: ' +
        buttonConfig.fontSize +
        'px;' +
        (buttonConfig.gradient
          ? '\
						background: -moz-linear-gradient(top, ' +
            buttonConfig.color +
            ' 0%, ' +
            buttonConfig.color2 +
            ' 100%);\
						background: -webkit-gradient(left top, left bottom, color-stop(0%, ' +
            buttonConfig.color +
            '), color-stop(100%, ' +
            buttonConfig.color2 +
            '));\
						background: -webkit-linear-gradient(top, ' +
            buttonConfig.color +
            ' 0%, ' +
            buttonConfig.color2 +
            ' 100%);\
						background: -o-linear-gradient(top, ' +
            buttonConfig.color +
            ' 0%, ' +
            buttonConfig.color2 +
            ' 100%);\
						background: -ms-linear-gradient(top, ' +
            buttonConfig.color +
            ' 0%, ' +
            buttonConfig.color2 +
            ' 100%);\
						background: linear-gradient(to bottom, ' +
            buttonConfig.color +
            ' 0%, ' +
            buttonConfig.color2 +
            " 100%);\
						filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='" +
            buttonConfig.color +
            "', endColorstr='" +
            buttonConfig.color2 +
            "', GradientType=0 );\
					"
          : '')
      )
    },
    containerWidth(buttonConfig: ButtonObject) {
      return (
        (parseInt(buttonConfig.width) + parseInt(buttonConfig.spacing) * 2) *
        parseInt(buttonConfig.columns)
      )
    },
  },
  created() {
    this.bindButtonsToDb()
  },
})
