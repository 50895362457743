var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"ma-4",attrs:{"flat":_vm.display === 'inner'}},[_c('v-card-title',[_vm._v(" User List "),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-card-text',[_c('ActionFeedbackDisplay',{attrs:{"actionFeedbackType":"updateUserPermission"}}),_c('v-data-table',{attrs:{"headers":_vm.usersHeader,"items":_vm.getUsers,"item-class":_vm.userRowClass,"search":_vm.search,"items-per-page":_vm.itemsPerPage,"footer-props":_vm.footerProps,"loading-text":"Loading... Please wait"},scopedSlots:_vm._u([{key:"item.avatar",fn:function(ref){
var item = ref.item;
return [_c('v-avatar',{attrs:{"size":"34","rounded":"","color":"indigo"}},[(item.photoURL)?_c('img',{attrs:{"src":item.photoURL,"alt":item.displayName}}):_c('span',{staticClass:"white--text text-h5"},[_vm._v(" "+_vm._s(item.displayName ? item.displayName[0] : item.email[0])+" ")])])]}},{key:"item.lastLogin",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.lastLogin.toDate())+" ")]}},{key:"item.role",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getPermissionsForUser(item.id).role)+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(_vm.getAdminViewUserId == item.id)?_c('v-btn',{staticClass:"mr-2",attrs:{"small":"","color":"primary"},on:{"click":function($event){return _vm.viewUsersContent('')}}},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v(" mdi-eye ")]),_vm._v("Unview Content ")],1):_c('v-btn',{staticClass:"mr-2",attrs:{"small":"","color":"secondary"},on:{"click":function($event){return _vm.viewUsersContent(item.id)}}},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v(" mdi-eye ")]),_vm._v("View Content ")],1),_c('v-dialog',{attrs:{"max-width":"500px","retain-focus":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"accent","small":""},on:{"click":function($event){return _vm.editItem(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v(" mdi-pencil ")]),_vm._v("Edit Permissions ")],1)]}}],null,true),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"text-h5"},[_vm._v("Edit User Permissions")])]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"label":"Email","readonly":""},model:{value:(_vm.editedUser.user.email),callback:function ($$v) {_vm.$set(_vm.editedUser.user, "email", $$v)},expression:"editedUser.user.email"}})],1),_c('v-col',[_c('v-select',{attrs:{"items":_vm.roles,"label":"Role"},model:{value:(_vm.editedUser.permissions.role),callback:function ($$v) {_vm.$set(_vm.editedUser.permissions, "role", $$v)},expression:"editedUser.permissions.role"}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.close}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.saveUser}},[_vm._v(" Save ")])],1)],1)],1)]}}],null,true)})],1),_c('v-card-actions')],1)}
var staticRenderFns = []

export { render, staticRenderFns }