































































































































import Vue from 'vue'
import { mapGetters, mapActions } from 'vuex'
// import * as firebase from 'firebase/app'
import ActionFeedbackDisplay from '@/components/ActionFeedbackDisplay.vue'
import { UserData, PermissionData } from '@/models/interfaces'

export default Vue.extend({
  name: 'UserList',
  components: {
    ActionFeedbackDisplay,
  },
  props: {
    display: String,
  },
  data() {
    return {
      search: '',
      itemsPerPage: 20,
      dialog: false,
      editedUser: {
        user: {},
        permissions: {},
      },
      usersHeader: [
        { text: '', value: 'avatar' },
        { text: 'Email', value: 'email' },
        { text: 'Display name', value: 'displayName' },
        { text: 'Last log-in', value: 'lastLogin' },
        { text: 'Access role', value: 'role' },
        { text: 'Actions', value: 'actions' },
      ],
      footerProps: {
        itemsPerPageOptions: [20, 50, 100, -1],
      },
      roles: ['user', 'admin'],
    }
  },
  computed: {
    ...mapGetters([
      'getUserPermissions',
      'getUsers',
      'getUsersPermissions',
      'getAdminViewUserId',
    ]),
  },
  // watch: {
  //   dialog(val) {
  //     val || this.close()
  //   },
  // },
  methods: {
    ...mapActions([
      'bindUsersToDb',
      'bindUsersPermissionsToDb',
      'updateUserPermission',
      'updateAdminViewUserId',
    ]),
    userRowClass(item: UserData) {
      if (item.id === this.getAdminViewUserId) {
        return 'selected-user'
      } else {
        return ''
      }
    },
    getPermissionsForUser(userId: string | undefined) {
      const permissions = this.getUsersPermissions.find(
        (permission: PermissionData) => permission.id === userId
      )
      if (permissions) {
        return permissions
      } else {
        return {}
      }
    },
    viewUsersContent(userId: string) {
      this.updateAdminViewUserId(userId)
    },
    editItem(user: UserData) {
      const permissions = this.getPermissionsForUser(user.id)
      this.editedUser = { user: user, permissions: permissions }
      this.dialog = true
    },
    close() {
      this.dialog = false
      this.editedUser = { user: {}, permissions: {} }
    },
    saveUser() {
      // is admin?
      if (this.getUserPermissions.role != 'admin') {
        return
      }
      // call store action to update user
      const editedUser = {
        user: this.editedUser.user,
        permissions: this.editedUser.permissions,
      }
      // console.log(newPermissions)
      this.updateUserPermission(editedUser)
      this.close()
    },
  },
  created() {
    this.bindUsersToDb()
    this.bindUsersPermissionsToDb()
  },
})
