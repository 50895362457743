<template>
  <v-row align="stretch" align-content="start" justify="center" no-gutters>
    <v-col>
      <v-card class="ma-4">
        <v-card-text>
          <v-tabs>
            <v-tab href="#userlist"> User List </v-tab>
            <v-tab-item value="userlist">
              <UserList :display="'inner'" />
            </v-tab-item>
            <v-tab href="#userbuttons"> User's Buttons </v-tab>
            <v-tab-item value="userbuttons">
              <ButtonList :admin-view="true" />
            </v-tab-item>
          </v-tabs>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
// @ is an alias to /src
import UserList from '@/components/UserList.vue'
import ButtonList from '@/components/ButtonList.vue'

export default {
  name: 'SystemAdmin',
  components: {
    UserList,
    ButtonList,
  },
}
</script>
